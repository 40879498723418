import React from 'react';
import { bool, shape, string } from 'prop-types';

import { loadable } from 'nordic/lazy';
import HeroSection from '@pog-ui-landings-library/hero-section';
import FixedActionsSection from '@pog-ui-landings-library/fixed-actions-section';
import StickySection from '@pog-ui-landings-library/sticky-section';
import RemoteModule from '@pog-ui-landings-library/remote-module';

import lazyWrapper from '../../../utils/lazy-wrapper';
import { getLazyMode } from '../utils/section-helpers';

const BusinessTools = loadable(async () =>
  import('@pog-ui-landings-library/business-tool'),
);
const CardList = loadable(async () =>
  import('@pog-ui-landings-library/card-list'),
);
const Faqs = loadable(async () => import('@pog-ui-landings-library/faqs'));
const FilteredCards = loadable(async () =>
  import('@pog-ui-landings-library/filtered-cards'),
);
const GenericComponent = loadable(async () =>
  import('@pog-ui-landings-library/generic-component'),
);
const Hero = loadable(async () => import('@pog-ui-landings-library/hero'));
const Payments = loadable(async () =>
  import('@pog-ui-landings-library/payments'),
);
const PaymentMethods = loadable(async () =>
  import('@pog-ui-landings-library/payment-methods'),
);
const PaymentMethodsSection = loadable(async () =>
  import('@pog-ui-landings-library/payment-methods-section'),
);
const PreFooterSection = loadable(async () =>
  import('@pog-ui-landings-library/prefooter-section'),
);
const Taxes = loadable(async () => import('@pog-ui-landings-library/taxes'));
const Tyc = loadable(async () => import('@pog-ui-landings-library/tyc'));
const TycSection = loadable(async () =>
  import('@pog-ui-landings-library/tyc-section'),
);

const BrandsSection = loadable(async () =>
  import('@pog-ui-landings-library/brands-section'),
);
const CardListSection = loadable(async () =>
  import('@pog-ui-landings-library/card-list-section'),
);
const CardsMediaSection = loadable(async () =>
  import('@pog-ui-landings-library/cards-media-section'),
);
const FaqsSection = loadable(async () =>
  import('@pog-ui-landings-library/faqs-section'),
);
const FeesSection = loadable(async () =>
  import('@pog-ui-landings-library/fees-section'),
);
const FilteredCardsSection = loadable(async () =>
  import('@pog-ui-landings-library/filtered-cards-section'),
);
const FormSection = loadable(async () =>
  import('@pog-ui-landings-library/form-section'),
);
const ImageTextSection = loadable(async () =>
  import('@pog-ui-landings-library/image-text-section'),
);
const ListSection = loadable(async () =>
  import('@pog-ui-landings-library/list-section'),
);
const ModalSection = loadable(async () =>
  import('@pog-ui-landings-library/modal-section'),
);
const MultiproductWrapper = loadable(async () =>
  import('@pog-ui-landings-library/multiproduct-wrapper'),
);
const NavBar = loadable(async () => import('@pog-ui-landings-library/navbar'));
const NavBarSection = loadable(async () =>
  import('@pog-ui-landings-library/navbar-section'),
);
const Opportunity = loadable(async () =>
  import('@pog-ui-landings-library/opportunity'),
);
const ShortcutsSection = loadable(async () =>
  import('@pog-ui-landings-library/shortcuts-section'),
);
const TaxesHybrid = loadable(async () =>
  import('@pog-ui-landings-library/taxes-hybrid'),
);
const TitleOnlySection = loadable(async () =>
  import('@pog-ui-landings-library/title-only-section'),
);
const VideoTextSection = loadable(async () =>
  import('@pog-ui-landings-library/video-text-section'),
);

// eslint-disable-next-line complexity
const CalmComponent = ({
  id,
  config,
  type,
  moduleKey,
  isMobile,
  isWebView,
  theme,
  sectionMetadata,
}) => {
  const { lazyMode: desiredLazyMode } = config || {};

  const lazyMode = getLazyMode(type, desiredLazyMode);

  switch (type) {
    case 'brands-section':
      return lazyWrapper(
        <BrandsSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          sectionMetadata={sectionMetadata}
        />,
        lazyMode,
      );

    case 'business-tool':
      return lazyWrapper(
        <BusinessTools
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />,
        lazyMode,
      );

    case 'card-list':
      return lazyWrapper(
        <CardList
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />,
        lazyMode,
      );

    case 'card-list-section':
      return lazyWrapper(
        <CardListSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          andesTheme={theme}
          sectionMetadata={sectionMetadata}
        />,
        lazyMode,
      );

    case 'cards-media-section':
      return lazyWrapper(
        <CardsMediaSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          andesTheme={theme}
          sectionMetadata={sectionMetadata}
        />,
        lazyMode,
      );

    case 'faqs':
      return lazyWrapper(
        <Faqs {...config} id={id} isMobile={isMobile} isWebView={isWebView} />,
        lazyMode,
      );

    case 'faqs-section':
      return lazyWrapper(
        <FaqsSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          sectionMetadata={sectionMetadata}
        />,
        lazyMode,
      );

    case 'fees-section':
      return lazyWrapper(
        <FeesSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          sectionMetadata={sectionMetadata}
        />,
        lazyMode,
      );

    case 'filtered-cards':
      return lazyWrapper(
        <FilteredCards
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />,
        lazyMode,
      );

    case 'filtered-cards-section':
      return lazyWrapper(
        <FilteredCardsSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          andesTheme={theme}
          sectionMetadata={sectionMetadata}
        />,
        lazyMode,
      );

    case 'fixed-actions-section':
      return (
        <FixedActionsSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          sectionMetadata={sectionMetadata}
        />
      );

    case 'form-section':
      return lazyWrapper(
        <FormSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          andesTheme={theme}
          sectionMetadata={sectionMetadata}
        />,
        lazyMode,
      );

    case 'generic-component':
      return lazyWrapper(
        <GenericComponent
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />,
        config?.video ? 'none' : lazyMode,
      );

    case 'hero':
      return lazyWrapper(
        <Hero {...config} id={id} isMobile={isMobile} isWebView={isWebView} />,
        lazyMode,
      );

    case 'hero-section':
      return (
        <HeroSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          andesTheme={theme}
          sectionMetadata={sectionMetadata}
        />
      );

    case 'image-text-section':
      return lazyWrapper(
        <ImageTextSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          andesTheme={theme}
          sectionMetadata={sectionMetadata}
        />,
        lazyMode,
      );

    case 'list-section':
      return lazyWrapper(
        <ListSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          andesTheme={theme}
          sectionMetadata={sectionMetadata}
        />,
        lazyMode,
      );

    case 'modal-section':
      return lazyWrapper(
        <ModalSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          isAdmin={false}
          sectionMetadata={sectionMetadata}
        />,
        lazyMode,
      );

    case 'multiproduct-wrapper':
      return lazyWrapper(
        <MultiproductWrapper
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />,
        lazyMode,
      );

    case 'navbar':
      return lazyWrapper(
        <NavBar
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />,
        lazyMode,
      );

    case 'navbar-section':
      return lazyWrapper(
        <NavBarSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          sectionMetadata={sectionMetadata}
        />,
        lazyMode,
      );

    case 'opportunity':
      return lazyWrapper(
        <Opportunity
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />,
        lazyMode,
      );

    case 'payments':
      return lazyWrapper(
        <Payments
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />,
        lazyMode,
      );

    case 'payment-methods':
      return lazyWrapper(
        <PaymentMethods
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />,
        lazyMode,
      );

    case 'payment-methods-section':
      return lazyWrapper(
        <PaymentMethodsSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          sectionMetadata={sectionMetadata}
          andesTheme={theme}
        />,
        lazyMode,
      );

    case 'prefooter-section':
      return lazyWrapper(
        <PreFooterSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          sectionMetadata={sectionMetadata}
        />,
        lazyMode || 'whenVisible',
      );

    case 'remote-module': {
      const { remoteModule } = config;

      if (!remoteModule) {
        return null;
      }

      const { name, host, moduleSetup } = remoteModule;

      if (!name || !host) {
        return null;
      }

      return (
        <RemoteModule
          {...remoteModule}
          id={id}
          isMobile={isMobile}
          data={moduleSetup}
          moduleId={moduleKey}
          isWebView={isWebView}
          sectionMetadata={sectionMetadata}
        />
      );
    }

    case 'shortcuts-section':
      return lazyWrapper(
        <ShortcutsSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          sectionMetadata={sectionMetadata}
        />,
        lazyMode,
      );

    case 'sticky-section':
      return (
        <StickySection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          sectionMetadata={sectionMetadata}
        />
      );

    case 'taxes':
      return lazyWrapper(
        <Taxes {...config} id={id} isMobile={isMobile} isWebView={isWebView} />,
        lazyMode,
      );

    case 'taxes-hybrid':
      return lazyWrapper(
        <TaxesHybrid
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
        />,
        lazyMode,
      );

    case 'title-only-section':
      return lazyWrapper(
        <TitleOnlySection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          andesTheme={theme}
          sectionMetadata={sectionMetadata}
        />,
        lazyMode,
      );

    case 'tyc':
      return lazyWrapper(
        <Tyc {...config} id={id} isMobile={isMobile} isWebView={isWebView} />,
        lazyMode,
      );

    case 'tyc-section':
      return lazyWrapper(
        <TycSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          sectionMetadata={sectionMetadata}
        />,
        lazyMode,
      );

    case 'video-text-section':
      return lazyWrapper(
        <VideoTextSection
          {...config}
          id={id}
          isMobile={isMobile}
          isWebView={isWebView}
          andesTheme={theme}
          sectionMetadata={sectionMetadata}
        />,
        lazyMode,
      );

    default:
      return null;
  }
};

CalmComponent.propTypes = {
  id: string,
  type: string,
  config: shape({
    lazyMode: string,
  }),
  sectionMetadata: shape({
    type: string,
    sectionId: string,
    sectionVersionId: string,
  }),
  moduleKey: string,
  isMobile: bool,
  isWebView: bool,
  theme: string,
};

export default CalmComponent;
